<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{
          id
            ? m.title + ` №${data.code_doc}`
            : "Новый документ '" + m.title + "'"
        }}
        <br />
        {{ config.name }}
      </template>
      <a-loader v-if="loading" />
      <v-row v-else>
        <v-col
          v-if="modelForm"
          v-for="(el, i) in modelForm"
          :key="i"
          cols="12"
          :sm="el.size"
          class="pb-0"
        >
          <a-form-model
            :ref="'field_' + el.name"
            v-model="data"
            :model="getFormModel([el.name])"
            :errors="errors"
            :config="{ dense: true }"
            @validate="validate($event)"
          />
        </v-col>
      </v-row>

      <template v-slot:actions>
        <a-btn-status
          v-if="getAccess('documents.cashOut.status')"
          :items="statusItems"
          @click="changeStatus($event)"
          :disabled="!canStatus"
        />
        <v-spacer></v-spacer>
        <a-btn-delete
          v-if="id && data.status != 2 && getAccess('documents.cashOut.delete')"
          @click="removeDialogShow = true"
        />
        <a-btn-save
          v-if="data.status === 1"
          @click="submit()"
          :color="isChanged ? 'green' : 'primary'"
        />

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>

    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import {
  getForm,
  submitForm,
  removeEl,
  getAccess,
  keyDetect,
} from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, keyDetect],
  props: {
    value: Boolean,
    id: Number,
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      m: this.config.m,
      api: this.config.m.api,
      apiStatus: this.config.m.api,
      removeDialogShow: false,
      showSelectDialog: false,
      loaded: false,
      baseId: null,
      modelForm: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.calcModel();
      },
    },
    modelDoc() {
      const m = this.$store.getters["config/get"].models.documentOrderGoods;
      let model = JSON.parse(JSON.stringify(m.list));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = m[el.sourceList];
        }
      });
      return model;
    },
    editObject() {
      return false;

    },
    canStatus() {
      return !this.loading && !this.isChanged && this.data.id;
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statuses = this.m.statuses;
      const statusCur = statuses.find((el) => el.value == this.data.status);
      if (statusCur && statusCur.next && statuses) {
        let arr = statusCur.next;
        res = statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      return items;
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (this.id) {
    } else {
      this.data.operation_type = this.config.id;
      if (this.document?.id) {
        this.onSelectDocument(this.document);
      } else {
        this.showSelectDialog = true;
      }
    }
  },
  watch: {
    value1() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.type = 1;
          //  this.data.status = 0;
        }
      }
    },
  },
  methods: {
    async submit() {
      if (await this.validateAll(this.data)) {
        //console.log('save', this.data);
        await this.post(this.data, this.api);
        return true;
      } else if (this.keys?.ctrl && this.$root.profile.role === 1000) {
        await this.post(this.data, this.api);
        return true;
      }

      return false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.isChanged = false;
      return;
      if (status) this.$emit("input");
    },
    afterFetchData(r) {
      if (this.id) {
      }
      this.loaded = true;
    },
    calcModel(t) {
      //оплата заказа товаров...
      const config = this.config;
      console.log("config", config);
      let model = JSON.parse(JSON.stringify(this.m.form));

      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = []; // config.fieldsRO ? config.fieldsRO.split(",") : [];
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = this.m.form;

      let modelForm = [];
      model.forEach((m) => {
        modelForm.push({ name: m.name, size: 6 });
      });
      this.modelForm = modelForm;
      return model;
    },
    async changeStatus(status) {
      let id = this.data.id;
      if (!id) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Что-то пошло не так....",
        });
        return;
      }
      this.loading = true;
      let data = { id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
  },
};
</script>